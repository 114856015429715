import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import MainMenu from './MainMenu';

import Button from '../ingka/Button';
import Text from '../ingka/Text';
import style from './header.module.scss'
import chevronDown from '@ingka/ssr-icon/paths/menu'
import back from '@ingka/ssr-icon/paths/arrow-left'
import cross from '@ingka/ssr-icon/paths/cross'
import logoutIcon from '@ingka/ssr-icon/paths/arrow-right-from-base'

import { useSelector, useDispatch } from "react-redux";
import { changeLang, clearRoute, doLogout, setMyStore } from '../../store/globalSlice';
import { NavLink, useNavigate } from 'react-router-dom';

import SelectIngka from '../ingka/SelectIngka';
import SSRIcon from '@ingka/ssr-icon';
import chevronRight from "@ingka/ssr-icon/paths/chevron-right-small";
import MotionLevelSelector from './MotionLevelSelector';
import Select from "./Select";
import { getStoreOptions } from '../../services/viewingMotionsServices';
import { clearAllMaps } from '../../store/canvasSlice';

import fullScreenIcon from '@ingka/ssr-icon/paths/fullscreen'

export default function Header() {
  const[clicked, setClicked] = useState(false)
  const [t, i18n] = useTranslation("global")
  const role = useSelector((state) => state.global.rol)
  const lang = useSelector((state) => state.global.language)
  const route = useSelector((state) => state.global.route)
  const maps = useSelector((state) => state.canvas.maps)
  const page = useSelector((state) => state.global.page)
  const myStore = useSelector((state) => state.global.myStore)
  const languages = [{name: t("language.labelEs"), value: "es"}, {name: t("language.labelEn"), value: "en"}]
  const dispatch = useDispatch();

  const [levelSelected, setLevelSelected] = useState(null)
  const [showSelector, setShowSelector] = useState(false)
  const [showRoute, setShowRoute] = useState(false)
  const [storeOptions, setStoreOptions] = useState(null)
  const [store, setStore] = useState(null)
  const navigate = useNavigate()
  const [fullScreen, setFullScreen] = useState(false)

  useEffect(() => {
    
    if(page !== "map"){
      setShowRoute(false)
    }else{
      setShowRoute(true)
    }
  },[page])

  
  const changeLanguage = (e)=>{
    dispatch(changeLang(e.target.value))
    i18n.changeLanguage(e.target.value)
    window.location.reload(false);
  }

  const toggleMenu = ()=>{
    setClicked(!clicked)
  }

  

  const logout = (e)=>{
    e.preventDefault()
    dispatch(doLogout())
  }

  const backToStart = () => {
    navigate(route[0].back, {replace: true})
    dispatch(clearRoute(null))
  }

  const openSelector = (level) => {
    toggleMenu()
    setLevelSelected(level)
    setShowSelector(true)
  }

  useEffect(() => {
    if(role.name === "SUPERADMIN"){
      setStore({label: myStore.name, value: myStore.id})
      getStoreOptions().then(stores => setStoreOptions(stores))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role])

  const changeStore = (val) => {
    setStore(val)
    dispatch(clearAllMaps())
    dispatch(setMyStore({id: val.id, name: val.name}))
    navigate('/')
  }

  const toggleFullScreen = () => {
    const docEl = document.documentElement;

    if (!fullScreen) {
      if (docEl.requestFullscreen) {
        docEl.requestFullscreen();
      } else if (docEl.mozRequestFullScreen) { // Firefox
        docEl.mozRequestFullScreen();
      } else if (docEl.webkitRequestFullscreen) { // Chrome, Safari, Opera
        docEl.webkitRequestFullscreen();
      } else if (docEl.msRequestFullscreen) { // IE/Edge
        docEl.msRequestFullscreen();
      } else {
        docEl.style.height = "100vh";
        docEl.style.width = "100vw";
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari, Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      } else {
        docEl.style.height = "";
        docEl.style.width = "";
      }
    }
    setFullScreen(!fullScreen);
  }

  return (
    <>
      <div className={style.header} >
        <div className='container-header'>
          <div className={style.left}>
          {!showRoute &&
            <>
              <Button 
                type="tertiary" 
                iconOnly 
                small
                ssrIcon={chevronDown} 
                className={style.ham_btn}
                onClick={()=>toggleMenu()}
              />
            
              <NavLink to="/" className={style.title}>
                <Text 
                  tagName="h1" 
                  headingSize="m" 
                  >
                    <span>Customer </span>
                    <span>Flow</span>
                </Text>
              </NavLink>
            </>
          }
          {showRoute && route &&
            <>
              <Button 
              type="tertiary" 
              iconOnly 
              small
              ssrIcon={back} 
              className={style.ham_btn}
              onClick={backToStart}
              />
              {
                route.map((item,index) => {
                  if(route.length-1 === index){
                    return(
                      <Text tagName="h1" headingSize="s" key={index} className={style.lastTitle}>
                        {item.back === "/maps" ? maps.find(map=> map.id ===item.id).name : item.title}
                      </Text>
                    )
                  } else {
                    return(
                      <NavLink to={item.path} className={style.route} key={index} onClick={() =>dispatch(clearRoute(item.title))}>
                        <Text tagName="h1" headingSize="s" className={style.title}>
                          {item.back === "/maps" ? maps.find(map=> map.id ===item.id).name : item.title}
                          <SSRIcon paths={chevronRight} />
                          </Text>
                      </NavLink>
                    )
                  }
                })
              }
            </>
          }
            
          </div>

          <div className={style.right}>
            {role.name === "SUPERADMIN" && storeOptions &&
              <Select
              formField={{className: style.storeSelector}}
              onChange={val => changeStore(val)}
              defaultValue={store}
              value={store}
              isClearable={false}
              isSearchable={true}
              id="store"
              name="store"
              options={storeOptions}
            />
            }
            <Button 
              className={`${style.fullScreen} ${fullScreen && style.activeFullScreen}`}
              type="tertiary" 
              iconOnly 
              small 
              ssrIcon={fullScreenIcon} 
              onClick={()=> toggleFullScreen()}
            />
          </div>

          {/* Side menu */}
          <aside className={`${style.side_menu} ${clicked ? style.open : ''}`}>
            <div className={style.side_menu_header}>
            <Text 
              tagName="h1" 
              headingSize="m" 
              className={style.title}>
                <span>Customer </span>
                <span>Flow</span>
            </Text>
              <Button 
                type="tertiary" 
                iconOnly 
                small 
                ssrIcon={cross} 
                onClick={()=>toggleMenu()}
              />
            </div>
            <div className={style.side_menu_body}>
              <MainMenu changeView={()=>toggleMenu()} openSelector={openSelector}/>
            </div>
            <div className={style.side_menu_footer}>
              <SelectIngka
                select={{
                  onChange: changeLanguage,
                  id: "langSelector",
                  value: lang
                }}
                options={languages}
              />
              <Button 
                type="secondary"
                className={style.logout}
                text={t("header.logoutButton")}
                small
                iconPosition="trailing"
                ssrIcon={logoutIcon} 
                onClick={(e) => logout(e)}
              />
            </div>
          </aside>
          <div className={`${style.overlay} ${clicked ? style.open : ''}`}></div>
        </div>
      </div>

      <MotionLevelSelector
      show={showSelector}
      level={levelSelected}
      hide={() => setShowSelector(!showSelector)}
      />
    </>
  )
}
